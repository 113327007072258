import { render, staticRenderFns } from "./ChainTools.vue?vue&type=template&id=8476fa40&scoped=true&lang=pug"
import script from "./ChainTools.vue?vue&type=script&lang=js"
export * from "./ChainTools.vue?vue&type=script&lang=js"
import style0 from "./ChainTools.vue?vue&type=style&index=0&id=8476fa40&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8476fa40",
  null
  
)

export default component.exports